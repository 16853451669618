import ReactDOM from "react-dom/client";
import React from "react";
import { CatalogWrapper } from "./components/catalog/Catalog";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

const domContainers = <NodeListOf<HTMLElement>>document.querySelectorAll(".catalog-section");
domContainers.forEach((container) => {
    const root = ReactDOM.createRoot(container);
    root.render(
        React.createElement(CatalogWrapper, {
            heading: container.dataset.heading,
            token: container.dataset.token,
            url: container.dataset.url,
            csrf: container.dataset.csrf,
            configuratorUrl: container.dataset.configuratorUrl,
        })
    );
});
